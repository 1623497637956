.hidden {
    display: none;
  }
  
  #mn {
    width: 100%;
    height: auto;
  }
  
  .card-login {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .card-login button {
    flex-grow: 1;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
    margin-right: 10px;
    padding: 7px;
    border: none;
    outline: none;
    border-radius: 25px;
    cursor: pointer;
  }
  
  .card-login button.active {
    background-color: #a6004d;
  }
  
  /* Add these styles to your CSS file */
.white-background {
  background-color: white;
}

.block-background {
  padding: 2px;
  background-color: #6e6b6c; /* Adjust the color as needed */
  color: white; /* Text color for block background */
}



